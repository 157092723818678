@font-face {
  font-family: poppins;
  src: url(/public/assets/fonts/Poppins-Regular.ttf);
  font-display:swap !important;
}
@font-face {
  font-family: poppins-bold;
  src: url(/public/assets/fonts/Poppins-Bold.ttf);
  font-display:swap !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* custom css */

body{
  background-color: #091020 !important;
}
.left-navigation .active.nav-link:before{
content: url('/public/assets/images/active-link-pointer.png');
position: absolute;
left: 0px;
top: 0px;

}
.nav-link{
  color: #fff !important;
}
.left-navigation .nav-link{
  position: relative;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
a{
  text-decoration: unset !important;
}
/* .first-link:focus,.second-link{
  background-color: red;
} */
/* right side bar animation */

/* end right sidebar animation */

.mobile-navigation,.no-nft{
  display: none;
}
.first-time-user .no-nft{
  display: block;
}
.first-time-user .dashboard-rideside-text.d-flex{
  display: none !important;
}
.below-btn-mobile{
  display: none !important;
}
.form-control:focus{
  box-shadow: unset !important;
    border: 1px solid #5ED3D0 !important;
}
.copy-icon{
  flex-grow: 100;
}
input{
  color: #fff !important;
  font-family: 'Poppins' !important;
}
.main-row.row {
  max-width: 1440px;
  margin: 0 auto !important;
}
/* button hover */
.round-bg-btn,.border-btn{
  /* transition: transform .2s; */
  /* transition:  .5s all ease-in-out;  */
  transition: transform .5s ease;
  /* transition: transform 1s, filter 2s ease-in-out;
  filter: blur(2px);
  transform: scale(1.2);
  */
} 
.round-bg-btn:hover{
  /* filter: blur(0);
  transform: scale(1); */
  transform: scale(0.9);
  box-shadow:-2px 1px 5px 6px rgb(94 211 208 / 15%);
  background-color: unset !important;
}
.border-btn:hover{
  transform: scale(.9);
  background-color: unset !important;
}
/* end button hover */
.connectwallet-box-main {
  width: 982px;
  padding: 40px 0px;
  margin: 0 auto;
  background-image: url(/public/assets/images/u.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: -30px;
  background-position-x: center;
}
.sub-text-14{
  font-family: 'Poppins' !important;

font-weight: 500 !important;
font-size: 14px !important;
line-height: 21px !important;
}
.connectwallet-box {
  max-width: 635px;
  margin: 0 auto;
  margin-top: 53px;
  padding: 40px;
  background: #1C2135;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 48px;
}
.wallet-heading{
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 2px;
}
.round-bg-btn{

font-family: 'Poppins' !important;
font-weight: 700 !important;
font-size: 20px !important;
line-height: 30px !important;
text-align: center !important;
color: #1C2135 !important;
padding: 15px 36px !important;
border-radius: 30px !important;
margin-top: 33px;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
background-image: linear-gradient(to right, #5ED3D0 , #5ED3D0);
border: unset !important;
background: linear-gradient(91.87deg, #5ED3D0 0.85%, #89A2FB 100%);
}
.border-btn{
  font-family: 'Poppins' !important;
font-weight: 700 !important;
font-size: 20px !important;
line-height: 30px !important;
  background: linear-gradient(91.87deg, #5ED3D0 0.85%, #89A2FB 100%) !important;
-webkit-background-clip: text !important;
-webkit-text-fill-color: transparent !important;
background-clip: text !important;
text-fill-color: transparent !important;
/* border: 3px solid transparent !important;
            border-image: linear-gradient(to right, #5ED3D0, #89A2FB) !important;
            border-image-slice: 1 !important;
            border-radius: 40px !important; */
            position: relative;
            border: unset !important;
  padding: 15px 36px !important;
}
.border-btn:before{
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 40px;
  padding: 3px; /* control the border thickness */
  background: linear-gradient(91.87deg, #5ED3D0 0.85%, #89A2FB 100%);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
  pointer-events: none;
}
.connectwallet-gif{
  position: absolute;
  top: -3%;
  right: -130px;
      width: 552px;
}
.or-divider{
  width: 429px;
}
/* left naviagtion  */
.left-navigation{
  background: #1C2135;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 16px;
  padding: 30px 20px !important;
  gap:40px;
  width: 92px;
  padding-left: 0px  !important;
  padding-right: 0px !important;
}
.desktop-navigation{
  width: 92px;
}
.left-navigation-bottom{
  padding: 30px 20px !important;
}
/* end left anvigation */
.dashboard-top-images-grid .d-flex{
  gap: 30px;
}
.grey-box{
  background: #1C2135 !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
 
  border-radius: 24px;
}
.firsttime-grey-box{
  padding: 0px 20px 20px 58px;
}
.white-heading{
  font-family: 'Poppins';
font-weight: 700;
font-size: 32px;
line-height: 48px;
letter-spacing: 2px;
}
.arrow-btn-text{
  font-family: 'poppins';
font-weight: 700;
font-size: 20px;
line-height: 30px;
background: linear-gradient(91.63deg, #5ED3D0 1.38%, #A188F5 73.06%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.top-images-two {
  margin-left: -70px;
}
/* firsttime-user */

/* right side bar */
.right-bar-profile-div,.right-side-number,.tab-content-number{
  flex-grow: 100;
}
.right-sidebar-top .grey-box{
  border-radius: 12px;
}
.right-side-exiticon{
  margin-top: 5px;
}
.right-sidebar-top p{
  font-family: 'Poppins';
font-weight: 500;
font-size: 20px;
line-height: 30px;
}
.right-side-20,.tab-content-title{
  font-family: 'Poppins';
font-weight: 700;
font-size: 20px;
line-height: 30px;
letter-spacing: 1px;
}
.right-side-earning{
  margin-top: 31px;
}
.right-side-level{
  margin-top: 42px;
}
.right-side-text,.right-side-number{
  font-family: 'Poppins';
font-weight: 500;
font-size: 16px;
line-height: 24px;
}
/* end right side bar */

/* tabs */
.level-tabs{
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 15px;
}
.font-16{
  font-family: 'Poppins';
font-weight: 500;
font-size: 16px;
line-height: 24px;
}
.level-tabs .nav-link{
  padding: 0px;
  
}


.level-tabs .nav-link.disabled {
  filter: grayscale(51%);
}
.tab-title{
  bottom: 6px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
font-family: 'Poppins';
font-weight: 700;
font-size: 14px;
line-height: 21px;
letter-spacing: 2px;
}
.tab-content-subtext{
  font-family: 'Poppins';
font-weight: 500;
font-size: 13px;
line-height: 21px;
}
.tab-content-inputbox,.input-box{
  background-color: #091020 !important;
border-radius: 14px !important;
border: unset !important;
height: 45px;
}
.font-12{
  font-family: 'Poppins';
font-weight: 500;
font-size: 12px;
line-height: 18px;
}

.level-tabs .nav-link:hover,.level-tabs .nav-link:focus{
  border: unset !important;
  outline: unset  !important;
  box-shadow: unset !important;
  isolation: unset !important;
}
.level-tabs .nav-link .tab-box-one,
.level-tabs .nav-link .tab-box-two,
.level-tabs .nav-link .tab-box-three,
.level-tabs .nav-link .tab-box-four,
.level-tabs .nav-link .tab-box-five{
  position: relative;
  overflow: hidden;
  border-radius: 24px;
}
.level-tabs .nav-link .tab-box-one:focus-visible,
.level-tabs .nav-link .tab-box-two:focus-visible,
.level-tabs .nav-link .tab-box-three:focus-visible,
.level-tabs .nav-link .tab-box-four:focus-visible,
.level-tabs .nav-link .tab-box-five:focus-visible,.level-tabs .nav-link:focus-visible{
  outline: unset !important;
  box-shadow: unset !important;
  border: unset !important;
}
.level-tabs .nav-link .tab-box-one:before{
  background: linear-gradient(180deg, #A188F5 0%, #FFCB5B 100%);
}
.level-tabs .nav-link .tab-box-two:before,.level-tabs  .nav-link.active .tab-box-two{
  background: linear-gradient(180deg, #8750F3 0%, #89A2FB 100%);
}
.level-tabs .nav-link .tab-box-three:before,.level-tabs  .nav-link.active .tab-box-three{
  background: linear-gradient(180deg, #89A2FB 0%, #5ED3D0 100%);
}
.level-tabs .nav-link .tab-box-four:before,.level-tabs  .nav-link.active .tab-box-four{
  background:linear-gradient(180deg, #8750F3 0%, #A188F5 100%);
}
.level-tabs .nav-link .tab-box-five:before,.level-tabs  .nav-link.active .tab-box-five{
  background: linear-gradient(180deg, #FD8826 0%, #FDCF3E 100%);
}
.level-tabs .nav-link .tab-box-one:before,
.level-tabs .nav-link .tab-box-two:before,
.level-tabs .nav-link .tab-box-three:before,
.level-tabs .nav-link .tab-box-four:before,
.level-tabs .nav-link .tab-box-five:before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 150%;
  height: 150%;
  transition: transform 0.5s ease;
  border-radius: 50%;
}
.level-tabs .nav-link .tab-box-one:hover:before,
.level-tabs .nav-link .tab-box-two:hover:before,
.level-tabs .nav-link .tab-box-three:hover:before,
.level-tabs .nav-link .tab-box-four:hover:before,
.level-tabs .nav-link .tab-box-five:hover:before{
  transform: translate(-50%, -50%) scale(1);
  z-index: -1 !important;
}
.level-tabs  .nav-link.active{
  background-color: unset !important;
  border: unset !important;
}
.level-tabs  .nav-link.active .tab-box-one{
  background: unset;
  background-image: linear-gradient(180deg, #A188F5 0%, #FFCB5B 100%) !important;
  border: unset;
  border-radius: 24px !important;
}

.tab-gif-one,.tab-gif-two,.tab-gif-three,.tab-gif-four,.tab-gif-five{
  display: none;
  width: 167px !important;
  height: 180px !important;
}
.tab-image-one,.tab-image-two,.tab-image-three,.tab-image-four,.tab-image-five{
  width: 167px !important;
  height: 180px !important;
}
.level-tabs .nav-link :hover .tab-gif-one,.level-tabs  .nav-link.active .tab-gif-one,
.level-tabs .nav-link :hover .tab-gif-two,.level-tabs  .nav-link.active .tab-gif-two,
.level-tabs .nav-link :hover .tab-gif-three,.level-tabs  .nav-link.active .tab-gif-three,
.level-tabs .nav-link :hover .tab-gif-four,.level-tabs  .nav-link.active .tab-gif-four,
.level-tabs .nav-link :hover .tab-gif-five,.level-tabs  .nav-link.active .tab-gif-five{
  display: block !important;
  z-index: 999999 !important;
}
.level-tabs .nav-link :hover .tab-image-one,.level-tabs  .nav-link.active .tab-image-one,
.level-tabs .nav-link :hover .tab-image-two,.level-tabs  .nav-link.active .tab-image-two,
.level-tabs .nav-link :hover .tab-image-three,.level-tabs  .nav-link.active .tab-image-three,
.level-tabs .nav-link :hover .tab-image-four,.level-tabs  .nav-link.active .tab-image-four,
.level-tabs .nav-link :hover .tab-image-five,.level-tabs  .nav-link.active .tab-image-five{
  display: none !important;
}
.level-tabs  .nav-link.active .tab-box-one:before,
  .level-tabs  .nav-link.active .tab-box-two:before,
  .level-tabs  .nav-link.active .tab-box-three:before,
  .level-tabs  .nav-link.active .tab-box-four:before,
  .level-tabs  .nav-link.active .tab-box-five:before{
  display: none !important;
}
/* end tabs */
.top-images-one,.top-images-two {
  display: flex;
  gap: 30px;
}
/* end first time user */

/* dashboard */
.top-gif{
  height: 307px;
}
/* end dashboard */

/* referrals */
.referrals-bottom-div{
  height: 692px;
}
/* end referrals */

/* order my card */
.gradient-heading{
  background: linear-gradient(90deg, #5ED3D0 0%, #A188F5 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.font-24{
  font-family: 'Poppins';
font-weight: 700;
font-size: 24px;
line-height: 36px;
}
.form-label{
  font-family: 'Poppins';
font-weight: 700;
font-size: 16px;
line-height: 24px;
letter-spacing: 1px;
}
/* end order my card */

/* track my order */
.progress{
  visibility: visible !important;
  overflow: visible !important;
  background: #1C2135 !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  max-width: 657px;
  margin: 0 auto;
  height: 0.5rem !important;
}
.progress-bar{
  background-color: #5ED3D0  !important;
  position: relative;
  overflow: visible !important;
}
.progress-bar:after{
  content: " " !important;
  background-color: #5ED3D0;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -5px;
border-radius: 50%;
}
.progress-bar:before{
  content: " " !important;
  background-color: #5ED3D0;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -5px;
border-radius: 50%;
}
.progress-bar-fill-btn{
  font-family: 'Poppins' !important;
font-weight: 600 !important;
font-size: 16px !important;
line-height: 24px !important;
text-align: center !important;
color: #1C2135 !important;
background-color:#5ED3D0 !important;
padding: 12px 38px !important;
border: unset !important;
position: relative;
}
.progress-bar-grey-btn{
  font-family: 'Poppins' !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #fff !important;
  background: #1C2135 !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 12px 38px !important;
  border: unset !important;
  position: relative;
}
.progress-bar-fill-btn:after{
  content: "";
  position: absolute;
  top: -37%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #5ED3D0 transparent;
}
.progress-bar-grey-btn:after{
  content: "";
  position: absolute;
  top: -37%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #1C2135 transparent;
}
.progress-bar-buttons button{
  width: 183px;
}
.progress-bar-buttons{
  max-width: 876px;
    margin: 0 auto !important; 
}
/* end track my order */

/* profile */
.history-table thead,
.history-table thead span.react-bs-table-sizePerPage-dropdown.dropdown,
.history-table span.react-bootstrap-table-pagination-total,.history-table button#pageDropDown{
  display: none !important;
  visibility: hidden !important;
}
.history-table .page-link:focus{
  box-shadow: unset !important;
}
.history-table table{
  border-collapse: separate;
  border-spacing: 12px 16px;
}
.history-table table,.history-table tr,.history-table .table-bordered>:not(caption)>*>*{
  border-width: 0px !important;
}
.history-table td:nth-child(1) {
  width: 210px !important;
}
.history-table td{
  background: #1C2135 !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 24px;
  font-family: 'Poppins';
font-weight: 500;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
padding: 11px 30px !important;
text-align: left;
}
.history-table tr{
  margin-bottom: 16px;
}
.pagination li.page-item:nth-child(1),.pagination li.page-item:last-child {
  display: none;
}
.pagination a.page-link {
  background: #1C2135;
  border-radius: 12px;
  width: 45px;
  height: 45px !important;
  margin: auto;
  color: #fff !important;
  margin-right: 9px;
  border: unset !important;
  display:-webkit-box;
-webkit-box-pack:center;
-webkit-box-align:center;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
}
li.active.page-item a {
  background: linear-gradient(153.43deg, #89A2FB 0%, #A188F5 83.33%);
}
.history-filter-btns{
  padding-right: 12px;
  gap: 12px;
}
.history-filter-btn
{
  background: #1C2135 !important;
border-radius: 12px !important;
font-family: 'Poppins' !important;
font-weight: 500 !important;
font-size: 16px !important;
line-height: 24px !important;
padding: 11px 38px !important;
border: unset !important;
}
.history-filter-btn.active{
  background: linear-gradient(104.1deg, #89A2FB 0%, #8750F3 60.04%) !important;
}
.row{
  margin-left: 0px !important;
  margin-right: 0px !important;
}
/* end profile */
/* alerts */
.copy-alert-text{
  font-family: 'Poppins';
font-weight: 500;
font-size: 16px !important;
line-height: 21px;
color: #2F2F35 !important;
}
.copy-text-alert{
  background: linear-gradient(96.37deg, #5ED3D0 24.51%, #2FC1BD 55.02%);
border-radius: 12px;
width: max-content !important;
border: unset !important;
right: 0px;
}
.request-alert{
 background: linear-gradient(139.46deg, #FFCB5B 22.05%, #FF9337 76.95%);
}
.copy-text-alert button:focus{
  box-shadow: unset !important;
  outline: 0 !important;
}
.fade.copy-text-alert {
  position: absolute;
}
/* end alerts */

@media(max-width:1366px) and (min-width:1025px){
  .form-label{
    font-size: 14px !important;
  }
  .top-images-one,.top-images-two {
    display: grid !important;
    grid-template-columns: 50% 50%;
  }
  .top-images-one img,.top-images-two img {
    width: 100% !important;
  }
.white-heading {
  font-size: 28px;
  line-height: 35px !important;
}
.firsttime-grey-box {
  padding: 0px 20px 20px 30px;
}
.right-side-20, .tab-content-title,.arrow-btn-text,.round-bg-btn{
  font-size: 18px;
}
.top-box-col-one{
  width: 60% !important;
}
.top-box-col-two{
  width: 40% !important;
}
.top-box-heading{
  padding-right: 60px;
}
}
@media(max-width:1024px){
  .mobile-profile-nav{
    margin-left: -6px;
  }
  .firsttime-grey-box {
    padding: 20px;
}
.top-images-one,.top-images-two {
  display: grid !important;
  grid-template-columns: 50% 50%;
}
.top-images-one img,.top-images-two img {
  width: 100% !important;
}
.white-heading {
font-size: 28px;
line-height: 35px !important;
}
.firsttime-grey-box {
padding: 0px 20px 20px 30px;
}
.arrow-btn-text{
  font-size: 16px;
}
.white-heading{
  font-size: 25px;
  line-height: 39px;
}

/* mobile anvgation */
.d-block.desktop-navigation,.desktop-right-side-bar{
  display: none !important;
}
.mobile-navigation{
  display: block;
}
.mobile-navigation .fade:not(.show){
  display: none;
}
.mobile-navigation .fade.show{
  display: block;
  position: absolute;
  left: 20px;
  right: 20px;
  width: initial;
  z-index: 1;
}

.mobile-close-icon{
      /* display: flex; */
      flex-grow: 100;
      text-align: right;
}
.dark-grey-box{
  background: rgba(9, 16, 32, 0.8);
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
border-radius: 12px;
padding: 19px 27px;
}
.mobile-left-nav{
  margin-top: 28px;
}
.mobile-left-nav  a{
  padding: 0px;
  font-family: 'Poppins';
font-weight: 600;
font-size: 16px;
line-height: 36px;
  text-align: left;
}
.mobile-left-nav  a img{
  padding-right: 26px;
}
.mobile-bottom-logo{
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 16px;
}
.mobile-bottom-logo .active{
  filter: drop-shadow(0px 0px 12px #A689FB);
}
/* end mobile navigation */
}
@media(max-width:900px){
  .connectwallet-box-main img,.connectwallet-box-main{
    width: 100%;
  }
  .connectwallet-box-main{
    background-size: 100%;
  }
  .connectwallet-gif {
    
    right: 0px;
    z-index: -1;
}
.connectwallet-box{
  margin-top: 180px;
  margin-left: 30px;
  margin-right: 30px;
}
.wallet-heading{
  font-size: 20px !important;
  line-height: 30px !important;
}
.sub-text-14{
  font-size: 11px !important;
}
.sub-text-14 br{
  display: none;
}
.round-bg-btn,.border-btn{
  font-size: 16px !important;
}
.round-bg-btn{
  margin-top: 20px;
}
.connectwallet-box-main{
  margin-top: 30px;
}
/* first time user */
.top-images-one, .top-images-two{
  gap: 20px;
}
.top-images-one {
  margin-left: 40px;
 
}
.top-images-two {
  margin-left: -20px;
  margin-right: 40px;
}
/* .tab-main{
  overflow: auto;
} */
.level-tabs.d-grid{
  overflow-x: scroll;
   

}
.level-tabs.d-grid li{
 
    width: max-content;
    display: inline-flex !important;
    overflow-y:hidden;
    overflow-x: scroll;
}
.center-area-col,.left-area-col{
  padding: 0px !important;
}
.p-4.main-row{
  padding: 20px !important;
}
.white-heading.text-start{
  text-align: center !important;
  margin-bottom: 0px !important;
}
.white-heading.text-start.top-box-heading {
  text-align: left !important;
}
.top-box-heading{
  font-size: 20px !important;
  line-height: 30px !important;
}

.white-heading {
  font-size: 24px;
}
.font-16{
  font-size: 14px;
}
/* end first time user */

/* order my card */
.white-heading{
  letter-spacing: 0px !important;
}
.font-24{
  font-size: 20px !important;
}
.input-box{
  height: 40px;
}
.top-gif {
  height: auto;
}
.history-table td{
  font-size: 12px !important;
  line-height: 18px !important;
  padding: 16px 12px !important;
}
.history-table table{
  border-spacing: 9px 14px;
  margin-left: -10px;
  width: 107%;
}
.history-table td:nth-child(1) {
  width: 90px !important;
}
.react-bootstrap-table-pagination-list{
  justify-content: center;
  display: flex;
}
/* end order my card */
}
@media(max-width:600px){
  .connectwallet-box-main{
    background-image: url(/public/assets/images/bg-illustraction-mobile.png);
    background-position-y: 78px;
  }
  .connectwallet-box{
    padding: 40px 20px !important;
  }
  .w-100.tab-gif-one,.w-100.tab-gif-two,.w-100.tab-gif-three,.w-100.tab-gif-four,.w-100.tab-gif-five{
   
    width: inherit !important;
   
  }
  .w-100.tab-image-one,.w-100.tab-image-two,.w-100.tab-image-three,.w-100.tab-image-four,.w-100.tab-image-five{
    width: inherit !important;
    
  }
  .form-label{
    font-size: 13px !important;
  }
 
  .copy-text-alert{
    width: 100% !important;
  }
  .below-btn-mobile{
    display: block  !important;
  }
  .order-card-mobile-btn,.order-track-mobile-btn{
    margin-top: -20px;
  }
  .white-heading.text-start.top-box-heading{
    margin-bottom: 20px !important;
  }
.round-bg-btn{
  margin-left: auto;
  margin-right: auto;
}
  .below-btn-desktop{
    display: none !important;
  }
  p.arrow-btn-text.text-start {
    text-align: center !important;
  }
  .white-heading.text-start,.white-heading.text-start.top-box-heading {
    text-align: center !important;
}
/* track my order */
.progress {
  transform: rotate(90deg);
  transform-origin: left top;
}
.progress-bar-container{
  padding-bottom: 100px;
}
.progress-bar-buttons{
  gap: 39px;
}
.progress-bar-buttons>div {
  text-align: center;
  justify-content: center;
  display: flex;
}
.progress-bar-btn-main{
  margin-top: -70px !important;
}
.progress-bar-row.row{
  margin-top: 50px !important;
}
.progress-bar-fill-btn:after {
  content: "";
  position: absolute;
  top: 34%;
  transform: rotate(268deg);
  left: -14px;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #5ED3D0 transparent;
}
.progress-bar-grey-btn:after{
  content: "";
  position: absolute;
  top: 34%;
  transform: rotate(268deg);
  left: -14px;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #1C2135 transparent;
}
.progress-bar-row{
  max-width: 300px;
  margin: 0 auto !important;
}
/* end track my order */
}
@media(max-width:320px){
  .connectwallet-box {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

}
@media(max-width:769px) and (min-width:767px){
  .progress-bar-buttons button {
    width: auto;
}
.progress-bar-fill-btn,.progress-bar-grey-btn{
  font-size: 11px !important;
  padding: 10px 30px !important;
}
.progress{
  max-width: 512px !important;
}
}
@media  (max-device-width: 800px) and (orientation : landscape){
  .w-100.tab-gif-one,.w-100.tab-gif-two,.w-100.tab-gif-three,.w-100.tab-gif-four,.w-100.tab-gif-five{
   
    width: inherit !important;
   
  }
  .w-100.tab-image-one,.w-100.tab-image-two,.w-100.tab-image-three,.w-100.tab-image-four,.w-100.tab-image-five{
    width: inherit !important;
    
  }
.connectwallet-box-main
{
  
  background-position-y: 80px;

}
.connectwallet-gif{
  position: absolute;
  top: 27%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.connectwallet-box-main img{
  width: 76%;
  
}
.connect-logo{
  margin-bottom: 50px;
}
.top-box-col-two{
  max-width: 400px !important;
  margin: 0 auto;
}
.white-heading.text-start.top-box-heading,p.arrow-btn-text{
  text-align: center !important;
}
.round-bg-btn {
  justify-content: center !important;
  display: flex !important;
  margin-left: auto;
  margin-right: auto;
}
.progress {
  transform: rotate(90deg);
  transform-origin: left top;
}
.progress-bar-row {
  max-width: 300px;
  margin: 0 auto !important;
}
.progress-bar-fill-btn:after {
  content: "";
  position: absolute;
  top: 34%;
  transform: rotate(268deg);
  left: -14px;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #5ED3D0 transparent;
}
.progress-bar-grey-btn:after {
  content: "";
  position: absolute;
  top: 34%;
  transform: rotate(268deg);
  left: -14px;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #1C2135 transparent;
}
.progress-bar-buttons {
  gap: 39px;
}
.progress-bar-btn-main {
  margin-top: -70px !important;
}
.progress-bar-buttons{
  margin-left: 30px  !important;
}
.copy-text-alert{
  width: 100% !important;
}
}
/* end custom css */

